import React, { Suspense, useEffect, useState } from "react";
import { Container } from "react-bootstrap";

// constants
import { LayoutTypes, SideBarTheme, TopbarTheme } from "@/constants/layout";

// utils
import { changeBodyAttribute } from "@/utils";

import { MenuItemTypes } from "@/constants/menu";
import { getPortalType } from "../../helpers";
import Topbar from "../Topbar";
import Navbar from "./Navbar";
import Footer from "../Footer";

const loading = () => <div className="text-center"></div>;

interface HorizontalLayoutProps {
  children?: React.ReactNode;
  menuItems?: MenuItemTypes[];
  menuBarHidden?: boolean;
}

const HorizontalLayout = ({
  children,
  menuItems,
  menuBarHidden
}: HorizontalLayoutProps) => {
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);

  /*
    layout defaults
    */
  useEffect(() => {
    changeBodyAttribute("data-layout-mode", LayoutTypes.LAYOUT_HORIZONTAL);
    changeBodyAttribute("data-topbar-color", TopbarTheme.TOPBAR_THEME_LIGHT);
    changeBodyAttribute(
      "data-sidebar-color",
      SideBarTheme.LEFT_SIDEBAR_THEME_LIGHT
    );
    changeBodyAttribute("data-portal-type", `${getPortalType()}-portal`);
  }, []);

  /**
   * Open the menu when having mobile screen
   */
  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened);
    if (document.body) {
      if (isMenuOpened) {
        document.body.classList.remove("sidebar-enable");
      } else {
        document.body.classList.add("sidebar-enable");
      }
    }
  };

  return (
    <>
      <div id="wrapper">
        <Suspense fallback={loading()}>
          <Topbar
            menuBarHidden={menuBarHidden}
            openLeftMenuCallBack={openMenu}
          />
        </Suspense>
        {!menuBarHidden && (
          <Suspense fallback={loading()}>
            <Navbar menuItems={menuItems!} isMenuOpened={isMenuOpened} />
          </Suspense>
        )}
        <div className="content-page">
          <div className="content">
            <Container fluid>
              <Suspense fallback={loading()}>{children}</Suspense>
            </Container>
          </div>

          <Suspense fallback={loading()}>
            <Footer />
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default HorizontalLayout;
