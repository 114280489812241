import classNames from "clsx";
import { Collapse } from "react-bootstrap";

// components
import { MenuItemTypes } from "@/constants/menu";
import AppMenu from "./Menu";

interface NavbarProps {
  isMenuOpened?: boolean;
  menuItems: MenuItemTypes[];
}

const Navbar = ({ isMenuOpened, menuItems }: NavbarProps) => {
  // change the inputTheme value to light for creative theme
  const inputTheme = "light";

  return (
    <div className="topnav">
      <div className="container-fluid">
        <nav
          className={classNames(
            "navbar",
            "navbar-expand-lg",
            "topnav-menu",
            "navbar-" + inputTheme
          )}
        >
          <Collapse in={isMenuOpened} className="navbar-collapse">
            <div id="topnav-menu-content">
              <AppMenu menuItems={menuItems} />
            </div>
          </Collapse>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
