import { AxiosInstance } from "axios";
import { BaseApiModule } from "./base.module";
import { unstrapiMany, unstrapiOne } from "../../helpers/strapi";
import { UserSessionLibraryType } from "../../types/appointment-types";

export class UserSessionLibraryApiModule extends BaseApiModule {
  constructor(client: AxiosInstance) {
    super(client, "api");
  }

  async create(data: any) {
    return this.client
      .post("/api/user-session-libraries", { data })
      .then((res) => unstrapiOne<UserSessionLibraryType>(res.data, "userSessionLibrary"));
  }

  async update(id: number, data: any) {
    return this.client.put(`/api/user-session-libraries/${id}`, { data }).then(
      (res) => unstrapiOne<UserSessionLibraryType>(res.data, "userSessionLibrary")
    )
  }

  async findUserSession(userId: number, sessionId: number): Promise<UserSessionLibraryType[]> {

    return await this.client
      .get(`/api/user-session-libraries`, {
        params: {
          filters: {
            $and: [
              { user_setting: userId },
              { simulated_session: sessionId }
            ]
          },
          sort: "createdAt:DESC"
        }
      })
      .then((res) => {
        //console.debug(`raw thoughts - ${JSON.stringify(res)}`)
        return unstrapiMany<UserSessionLibraryType>(res.data, "userSessionLibrary")
      }

      );

  }

  async findAllUserSessions(userSettingsId: number): Promise<UserSessionLibraryType[]> {

    //console.debug (`findAllUserSessions ${userSettingsId}`)
    return await this.client
      .get(`/api/user-session-libraries`, {
        params: {
          populate:["simulated_session", "user_setting"],
          filters: {
            $and: [
              { user_setting: userSettingsId },
            ]
          },
          sort: "createdAt:DESC"
        }
      })
      .then((res) => {
        //console.debug(`raw User Sessions - ${JSON.stringify(res)}`)
        return unstrapiMany<UserSessionLibraryType>(res.data, "userSessionLibrary")
      },
      (err)=>{
        console.error (`An error occurred while fetching userTherapySessions`)
        return []
      }

      );
  }

}
