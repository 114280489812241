import "./assets/scss/Theme.scss";

import { useEffect } from "react";
import { getPortalType } from "./apps/common/helpers";
import { PortalTypes } from "./constants/portals";
import { useAuthStore } from "./apps/common/store/useAuthStore";
import RoutesHandler, {
  RoutesHandlerProps
} from "./apps/common/components/RoutesHandler";
import { useRequest } from "ahooks";
import { Spinner } from "react-bootstrap";

const clinicalRoutesParams = import("./apps/clinic/routes");
const memberRoutesParams = import("./apps/member/routes");
const providerRoutesParams = import("./apps/provider/routes");
const adminRoutesParams = import("./apps/admin/routes");

export default function App() {
  const portalType = getPortalType();

  const { data: params, loading } = useRequest(() => {
    let params: Promise<RoutesHandlerProps> = memberRoutesParams;

    if (portalType == PortalTypes.CLINICAL) {
      params = clinicalRoutesParams;
    } else if (portalType == PortalTypes.PROVIDER) {
      params = providerRoutesParams;
    } else if (portalType == PortalTypes.ADMIN) {
      params = adminRoutesParams;
    } else if (portalType == PortalTypes.PARTNER) {
      params = clinicalRoutesParams;
    }

    return params;
  });

  const { fetchUser } = useAuthStore();

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  if (loading || !params) {
    return <Spinner animation="border" role="status" />;
  }

  return <RoutesHandler {...params} />;
}
