import { AxiosInstance } from "axios";
import { unstrapiMany, unstrapiOne } from "../../helpers/strapi";
import { UserSettingsType } from "../../types/appointment-types";
import { BaseApiModule } from "./base.module";

export class UserSettingsApiModule extends BaseApiModule {
  constructor(client: AxiosInstance) {
    super(client, "api/user-settings");
  }

  async create(data: any) {
    return this.client
      .post("api/user-settings", { data })
      .then((res) => res.data);
  }

  getByUserId(id: number) {
    return this.client
      .get(this.buildPath(["by-user", String(id)]), {
        params: {
          filters: { user: { $eq: id } },
          populate: ["digital_session_subscription_type"]
        }
      })
      .then((res) => {
        //console.debug(`user_settings raw ${JSON.stringify(res.data)}`)
        return res.data as UserSettingsType

        //return unstrapiOne<UserSettingsType>(res.data, "userSettings")
      }
      );
  }

  async find() {
    return await this.client
      .get(this.buildPath())
      .then(
        (res) => unstrapiMany<UserSettingsType>(res.data, "userSettings")[0]
      );
  }

  update(id: number, data: Omit<UserSettingsType, "id">) {
    return this.client.put(this.buildPath(String(id)), { data });
  }

  async findById(id: number): Promise<UserSettingsType> {

    return await this.client.get(`api/user-settings/${id}`, {
      params: {
        populate: [
          "digital_session_subscription_type",
          "user_session_libraries"
        ],
      }
    }).then(

      (res) => {
        //console.debug ('Raw MemberService Payload ' + JSON.stringify(res.data.data.attributes.paymentLogs))
        return unstrapiOne<UserSettingsType>(res.data, "userSettings")

      }
    );
  }
}
