import { AxiosInstance, AxiosResponse } from "axios";
import { BaseApiModule } from "./base.module";

export class ContractsApiModule extends BaseApiModule {
  constructor(client: AxiosInstance) {
    super(client, "api");
  }

  async fetchContracts(contractSlug: string): Promise<AxiosResponse> {
    return await this.client.get(`api/contracts`, {
      params: {
        "filters[slug]": contractSlug
      }
    });
  }

  async signContract(slug: string): Promise<AxiosResponse> {
    //console.debug (`Signing Contract`)
    return await this.client.post(`api/contracts/${slug}`);
  }

  async  checkForLatestContracts(userId:number, slugs:string[]):Promise<string[]>{
    return await this.client.get(`api/checkContracts/${userId}`, {
      params: {
        slugs:slugs
      }
    }).then ((res)=>res.data);
  }
}
