import classNames from "clsx";
import React, { useMemo, useState, useEffect } from "react";
import { Link } from "react-router-dom";

// components
import MaximizeScreen from "@/apps/common/components/MaximizeScreen";
// import NotificationDropdown from "@/apps/common/components/NotificationDropdown";
import ProfileDropdown from "@/apps/common/components/ProfileDropdown";

// images
import logoDark from "@/assets/images/logo-skyler-dark.png";
import logoLight from "@/assets/images/logo-skyler-light.png";
import profilePic from "@/assets/images/users/avatar-11.png";
import avatar4 from "@/assets/images/users/avatar-4.jpg";
import { getFullName, getPortalTitle } from "../helpers";
import { useAuthStore } from "../store/useAuthStore";
import { useRequest } from "ahooks";

export interface NotificationItem {
  id: number;
  text: string;
  subText: string;
  icon?: string;
  avatar?: string;
  bgColor?: string;
}

// get the notifications
const Notifications: NotificationItem[] = [
  {
    id: 1,
    text: "Cristina Pride",
    subText: "Hi, How are you? What about our next meeting",
    avatar: profilePic
  },
  {
    id: 2,
    text: "Caleb Flakelar commented on Admin",
    subText: "1 min ago",
    icon: "uil uil-comment-message",
    bgColor: "primary"
  },
  {
    id: 3,
    text: "Karen Robinson",
    subText: "Wow ! this admin looks good and awesome design",
    avatar: avatar4
  },
  {
    id: 4,
    text: "New user registered.",
    subText: "5 hours ago",
    icon: "uil uil-user-plus",
    bgColor: "warning"
  },
  {
    id: 5,
    text: "Caleb Flakelar commented on Admin",
    subText: "1 min ago",
    icon: "uil uil-comment-message",
    bgColor: "info"
  },
  {
    id: 6,
    text: "Carlos Crouch liked Admin",
    subText: "13 days ago",
    icon: "uil uil-heart",
    bgColor: "secondary"
  }
];

// get the profilemenu
const ProfileMenus = [
  {
    label: "My Account",
    icon: "user",
    redirectTo: "/my-account"
  },
  // {
  //   label: "Lock Screen",
  //   icon: "lock",
  //   redirectTo: "/auth/lock-screen",
  // },
  {
    label: "Logout",
    icon: "log-out",
    redirectTo: "/auth/logout"
  }
];

export interface TopbarProps {
  hideLogo?: boolean;
  navCssClasses?: string;
  menuBarHidden?: boolean;
  openLeftMenuCallBack?: () => void;
}

const Topbar = ({
  hideLogo,
  navCssClasses,
  menuBarHidden,
  openLeftMenuCallBack
}: TopbarProps) => {
  const [isopen, setIsopen] = useState<boolean>(false);

  const navbarCssClasses: string = navCssClasses || "";
  const containerCssClasses: string = !hideLogo ? "container-fluid" : "";

  /**
   * Toggle the leftmenu when having mobile screen
   */
  const handleLeftMenuCallBack = () => {
    setIsopen(!isopen);
    if (openLeftMenuCallBack) openLeftMenuCallBack();
  };

  const [user, fetchUser] = useAuthStore((state) => [
    state.user,
    state.fetchUser
  ]);

  useRequest(() => fetchUser(), {
    pollingInterval: 5000,
    refreshDeps: [fetchUser],
    ready: user?.firstName === "."
  });

  const getProfileImage = (): string => {
    return user?.profilePicture?.url || profilePic;
  };

  const portalTitle = useMemo(() => {
    return getPortalTitle();
  }, []);

  return (
    <React.Fragment>
      <div className={`navbar-custom ${navbarCssClasses}`}>
        <div className={containerCssClasses}>
          {!hideLogo && (
            <div className="logo-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo">
                  <img src={logoDark} alt="" />
                </span>
              </Link>
              <Link to="/" className="logo logo-light">
                <span className="logo">
                  <img src={logoLight} alt="" />
                </span>
              </Link>
            </div>
          )}

          <div
            className="d-none d-md-block"
            style={{
              float: "left",
              height: "70px",
              lineHeight: "70px",
              verticalAlign: "middle",
              fontSize: "16px"
            }}
          >
            <strong>{portalTitle}</strong>
          </div>

          <ul className="list-unstyled topnav-menu float-end mb-0">
            <li className="dropdown d-none d-lg-inline-block">
              <MaximizeScreen />
            </li>
            {/* <li className="dropdown notification-list topbar-dropdown">
              <NotificationDropdown notifications={Notifications} />
            </li> */}
            <li className="dropdown notification-list topbar-dropdown">
              <ProfileDropdown
                profilePic={getProfileImage()}
                menuItems={ProfileMenus}
                username={getFullName(user!)}
              />
            </li>
          </ul>

          {!menuBarHidden && (
            <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
              {/* Mobile menu toggle (Horizontal Layout) */}
              <li>
                <Link
                  to="#"
                  className={classNames("navbar-toggle nav-link", {
                    open: isopen
                  })}
                  onClick={handleLeftMenuCallBack}
                >
                  <div className="lines">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </Link>
              </li>
            </ul>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Topbar;
