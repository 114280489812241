import { AxiosInstance } from "axios";
import { BaseApiModule } from "./base.module";
import { unstrapiMany } from "../../helpers/strapi";
import { InsuranceProviderType } from "../../types/appointment-types";

export class InsuranceProviderApiModule extends BaseApiModule {
  constructor(client: AxiosInstance) {
    super(client, "api");
  }

  create(data: InsuranceProviderType) {
    return this.client
      .post("/api/insurance-providers", { data })
      .then((res) => res.data);
  }

  async find(state: number) {
    //console.debug ('finding insurance providers for state ' + state)
    return await this.client
      .get(`/api/insurance-providers`, {
        params: {
          filters: { state: {$eq:state}},
        }
      })
      .then((res) => 
        
        unstrapiMany<InsuranceProviderType>(res.data, "insurance_provider")
      
      );
  }

  async findForDropDown(state: number) {
    //console.debug ('finding insurance providers for state ' + state)
    return await this.client
      .get(`/api/insurance-providers`, {
        params: {
          filters: { state: {$eq:state}},
        }
      })
  .then((response) => unstrapiMany(response.data, "insurance_provider"))
  .then((insuranceProviders) =>
    insuranceProviders.map((ip: any) => ({
      label: ip.description,
      value: ip.id
    }))
  );
}
}
