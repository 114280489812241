import { create } from "zustand";
import { api } from "../api-client";
import { LegacyUserType } from "../api-client/api-client.types";
//import { UserType} from "@/apps/common/types/appointment-types";
import { AxiosResponse } from "axios";
import mixpanel from "mixpanel-browser";
import {
  RegisterData,
  RegisterClinicData,
  validateReferralData,
  RegisterServiceProviderData
} from "../api-client/modules/auth-client.module";

type LoginCredentials = {
  email: string;
  password: string;
  memberEmail?: string;
};

type LoginParams = {
  payload: LoginCredentials;
  type: "login" | "member-impersonate";
};

type RegisterParams = {
  payload: RegisterData;
};

type RegisterClinicParams = {
  payload: RegisterClinicData;
};

type RegisterServiceProviderParams = {
  payload: RegisterServiceProviderData;
};

type validateReferralParams = {
  payload: validateReferralData;
};

export type StateType = {
  id: number;
  attributes: {
    name: string;
    code: string;
    createdAt: string;
    updatedAt: string;
    enabled: boolean;
  };
};

type AuthState = {
  loggedIn: boolean;
  token?: string;
  user?: LegacyUserType;
  states?: StateType[];
  handleToken(jwt: string): Promise<AxiosResponse>;
  login(params: LoginParams): Promise<AxiosResponse>;
  register(params: RegisterParams): Promise<AxiosResponse>;
  registerClinic(params: RegisterClinicParams): Promise<AxiosResponse>;
  registerServiceProvider(
    params: RegisterServiceProviderParams
  ): Promise<AxiosResponse>;
  validateReferral(params: validateReferralParams): Promise<AxiosResponse>;
  logout(): Promise<void>;
  fetchUser(): Promise<AxiosResponse>;
  fetchStates(): Promise<AxiosResponse>;
  fetchAccessCodeDetails(): Promise<AxiosResponse>;
};

export const useAuthStore = create<AuthState>()((set, get) => {
  const data = {
    loggedIn: false,
    token: undefined, // Now token is an object, which contains 2 thing refresh token and access token
    user: undefined,
    error: undefined,

    /**
     * Login the user
     */
    async login({ payload, type }: LoginParams) {
      return api.auth
        .login(
          {
            identifier: payload.email,
            password: payload.password,
            memberEmail: payload.memberEmail
          },
          type
        )
        .then((response) => {
          const { jwt } = response.data;
          return get().handleToken(jwt);
        });
    },

    async handleToken(jwt: string) {
      api.setTokens({ jwt });
      return get().fetchUser();
    },

    /**
     * Register the user
     */
    async register({ payload }: RegisterParams) {
      return api.auth.register(payload).then((response) => {
        const { jwt } = response.data;
        return get().handleToken(jwt);
      });
    },

    async registerClinic({ payload }: RegisterClinicParams) {
      return api.auth.registerClinic(payload).then((response) => {
        const { jwt } = response.data;
        return get().handleToken(jwt);
      });
    },

    async registerServiceProvider({ payload }: RegisterServiceProviderParams) {
      //console.debug("registerServiceProvider - " + JSON.stringify(payload));
      return api.auth.registerServiceProvider(payload).then((response) => {
        const { jwt } = response.data;
        return get().handleToken(jwt);
      });
    },

    async validateReferral({ payload }: validateReferralParams) {
      return api.auth.validateReferral(payload).then((response) => {
        return response;
      });
    },

    /**
     * Fetch the user
     */
    async fetchUser() {
      return api.auth
        .fetchUser()
        .then((response) => {
          const user = response.data;
          if (user.timezone == undefined) {
            user.timezone = "local";
          }

          // const userData: UserType = {
          //   id: user.id,
          //   firstName: user.firstName,
          //   lastName: user.lastName,
          //   dateOfBirth: user.dateOfBirth!,
          //   email: user.email,
          //   profilePicture: {
          //     url: user?.profilePicture?.url ? user?.profilePicture?.url : ""
          //   },
          //   state: {
          //     id: user.state.id,
          //     name: "",
          //     code: ""
          //   },
          // }

          set({ loggedIn: true, user });

          try {
            mixpanel.identify(user.email);
          } catch {
            // ignore
          }

          return response;
        })
        .catch((error) => {
          return error.response as AxiosResponse;
        });
    },

    /**
     * Fetch the states
     */
    async fetchStates() {
      return api.auth.fetchStates().then((response: any) => {
        const { data } = response.data;
        set({ states: data });
        //console.debug ('state -' + JSON.stringify(data))
        return response;
      });
    },

    async fetchAccessCodeDetails() {
      return api.auth.fetchAccessCodeDetails().then((response: any) => {
        const { data } = response.data;
        return response;
      });
    },

    /**
     * Logout the user
     */
    async logout() {
      set({ loggedIn: false, user: undefined, token: undefined });
      api.clearTokens();
    }
  };

  return data;
});
