import { AxiosInstance } from "axios";
import { BaseApiModule } from "./base.module";
import { unstrapiMany } from "../../helpers/strapi";
import { ThoughtJournalType } from "../../types/appointment-types";

export class ThoughtJournalApiModule extends BaseApiModule {
  constructor(client: AxiosInstance) {
    super(client, "api");
  }

  async create(data: any) {
    return this.client
      .post("/api/thought-journals", { data })
      .then((res) => res.data);
  }

  async update(id: number, data: any) {
    return this.client.put(`/api/thought-journals/${id}`, { data })
  }
  async findThoughtJournal(userId: number, sessionId: number): Promise<ThoughtJournalType[]> {

    return await this.client
      .get(`api/thought-journals`, {
        params: {
          filters: {
            $and: [
              { user: userId },
              { simulated_session: sessionId }
            ]
          },
          sort: "createdAt:DESC"
        }
      })
      .then((res) => {
        //console.debug(`raw thoughts - ${JSON.stringify(res)}`)
        return unstrapiMany<ThoughtJournalType>(res.data, "thoughtJournal")
      }

      );

  }

}
