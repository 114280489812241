import { AxiosInstance } from "axios";
import { BaseApiModule } from "./base.module";
import { unstrapiMany, unstrapiOne } from "../../helpers/strapi";
import { SimulatedSessionType } from "../../types/appointment-types";


export class SimulatedSessionApiModule extends BaseApiModule {
  constructor(client: AxiosInstance) {
    super(client, "api");
  }

  async fetchAllSessions({
    populate,
    filters
  }: { populate?: string[]; filters?: any } = {}) {
    return this.client
      .get(`/api/simulated-sessions`, {
        params: {
          populate: populate ?? ["audio", "tags"],
          sort: "createdAt:DESC",
          filters
        }
      })
      .then((res) => {
        const resp = unstrapiMany<SimulatedSessionType>(
          res.data,
          "simulated_session"
        );
        //console.log("Fetched Sessions - " + JSON.stringify(resp));
        return resp;
      });
  }

  update(id: number, data: any) {
    return this.client
      .put(`api/simulated-sessions/${id}`, { data })
      .then((res) =>
        unstrapiOne<SimulatedSessionType>(res.data, "simulated_session")
      );
  }
}
