const config = {
  APP_TITLE: "Skyler Health",
  API_URL: import.meta.env.VITE_API_URL,
  MIXPANEL_TOKEN: import.meta.env.VITE_MIXPANEL_TOKEN,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  SENTRY_ENVIRONMENT: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  CHAT_SERVER: import.meta.env.VITE_CHAT_SERVER,
  ENV: import.meta.env.VITE_ENV,
  COACHING: import.meta.env.VITE_ENV,
  SHAREASALE: import.meta.env.VITE_SHAREASALE
};

//console.debug (`config - ${JSON.stringify(config)}`)
export default config;
