import { MenuItemTypes } from "@/constants/menu";
import classNames from "clsx";
import FeatherIcon from "feather-icons-react";
import React, { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useCommonStore } from "@/apps/common/store/useCommonStore";

interface MenuItems {
  item: MenuItemTypes;
  tag?: string;
  linkClassName?: string;
  className?: string;
  subMenuClassNames?: string;
  activeMenuItems?: string[];
  toggleMenu?: (item: MenuItemTypes, status: boolean) => void;
}

const MenuItem = ({ item, className, linkClassName }: MenuItems) => {
  return (
    <li className={classNames("nav-item", className)}>
      <MenuItemLink item={item} className={linkClassName} />
    </li>
  );
};

const MenuItemLink = ({ item, className }: MenuItems) => {
  const menuClick = (e: React.MouseEvent) => {
    if (e.currentTarget.classList.contains("active")) {
      e.preventDefault();
      window.location.reload();
    }
  };

  return (
    <Link
      to={item.url!}
      target={item.target}
      className={classNames(className)}
      data-menu-key={item.key}
      onClick={menuClick}
    >
      {item.icon && <FeatherIcon icon={item.icon} className="hori-icon me-1" />}
      <span> {item.label} </span>
    </Link>
  );
};

/**
 * Renders the application menu
 */

interface AppMenuProps {
  menuItems: MenuItemTypes[];
}

const AppMenu = ({ menuItems }: AppMenuProps) => {
  const location = useLocation();
  const menuRef = useRef(null);
  const { canScheduleAppointment } = useCommonStore();
  const scheduleAppointment = {
    key: "schedule-appointment",
    icon: "calendar",
    label: "Schedule Another Session",
    isTitle: true,
    url: "/schedule-appointment"
  };
  const menuItemsClone = structuredClone(menuItems);

  if (canScheduleAppointment) {
    menuItemsClone.push(scheduleAppointment);
  }

  return (
    <ul className="navbar-nav" ref={menuRef} id="main-side-menu">
      {(menuItemsClone || []).map((item, idx) => {
        return (
          <MenuItem
            key={idx}
            item={item}
            className={classNames({
              active: location?.pathname === item.url
            })}
            linkClassName={classNames("nav-link", "dropdown-toggle", {
              active: location?.pathname === item.url
            })}
          />
        );
      })}
    </ul>
  );
};

export default AppMenu;
