import { AxiosInstance } from "axios";
import { BaseApiModule } from "./base.module";
import { unstrapiMany, unstrapiOne } from "../../helpers/strapi";
import { ChatSessionType } from "../../types/appointment-types";

export class ChatSessionApiModule extends BaseApiModule {
  constructor(client: AxiosInstance) {
    super(client, "api");
  }

  async find(id: number, populate: string[] = []) {

    const _populate = populate?.length > 0 ? populate : ["users.user.firstName"];
    //console.debug('populate ' + _populate)
    
    return await this.client
      .get(`api/chat-sessions/${id}`, {
        params: {
          populate: ["appointment.timeSlot.serviceProvider.user", "appointment.timeSlot.serviceProvider.user.profilePicture", "appointment.memberService.user.profilePicture", "appointment.memberService.user", ]
        }
      })
      .then((res) =>
      {
        //console.debug (`raw chatSession ${JSON.stringify(res)}`)
        return unstrapiOne<ChatSessionType>(res.data, "chat_session")
      },
      (err)=> {
        console.error (`ERROR fetching chatSession details - ${JSON.stringify(err)}`)
        return null}
      );
  }

  join (id:string) {

    console.debug (`chatSession.join id: ${id}`)
    return this.client.get(`api/chat-session/join/${id}`).then (
      (res) => {
        console.debug (`raw chatSessionToken - ${JSON.stringify(res.data)}`)
        return res.data
      },
      (err)=>{
        console.error (`ERROR fetching chatSessionToken - ${JSON.stringify(err)}`)
        return err
      }
    )
    


  } 

  // findMany({ populate, filters }: { populate?: string[], filters?: any } = {}) {
  //   return this.client
  //     .get(`api/service-providers`, {
  //       params: {
  //         populate,
  //         filters
  //       }
  //     })
  //     .then((res) =>
  //       unstrapiMany<ServiceProviderType>(res.data, "serviceProvider")
  //     );
  // }

  // update(id: number, data: Partial<ServiceProviderType>) {
  //   //console.debug('updating service provider profile - ' + JSON.stringify(data))
  //   return this.client
  //     .put(`api/service-providers/${id}`, { data })
  //     .then((res) =>
  //       unstrapiOne<ServiceProviderType>(res.data, "serviceProvider")
  //     );
  // }


}
